// import axios from '@/axios.js';
import { async } from '@team-decorate/vue-line-login'
import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
import router from '@/router'
import _ from 'lodash'
import moment from 'moment-timezone'
const axios = require('axios').default
const qs = require('qs')
export default {
  async setUser ({ commit, state }, user) {
    if (user.key) {
      const _user = (await firebase.database().ref(`/users/${user.key}`).once('value')).val()
      _user.key = user.key
      commit('SET_USER', _user)
      if (state.ref) state.ref.off()
      state.ref = firebase.database().ref('users/' + user.key)
      state.ref.on('child_changed', snapshot => {
        if (snapshot.key === 'credit' && state.user.credit < snapshot.val()) {
          commit('SET_CREDIT_INCREASE', true)
        } else if (snapshot.key === 'credit' && state.user.credit > snapshot.val()) {
          commit('SET_CREDIT_DECREASE', true)
        } else if (snapshot.key === 'coin') {
          commit('SET_COIN_VOICE', true)
        } else if (snapshot.key === 'status' && snapshot.val() === 'N') {
          firebase.auth().signOut()
          localStorage.removeItem('userData')
          router.replace('/login')
        }
        state.user[snapshot.key] = snapshot.val()
        commit('SET_USER', _user)
      })
    } else {
      firebase.auth().signOut()
      localStorage.removeItem('userData')
      router.replace('/login')
    }
  },
  async getBanners ({ commit }) {
    const banners = (await firebase.database().ref('/banners').orderByChild('status').equalTo('Y').once('value')).val()
    commit('SET_BANNERS', _.orderBy(banners, ['order'], ['asc']))
  },
  async getSites ({ commit, state }) {
    const sites = (await firebase.database().ref('/sites').orderByChild('used').equalTo(true).once('value')).val()
    commit('SET_SITES', _.orderBy(sites, ['order'], ['asc']))
    if (state.refsite) state.refsite.off()
    state.refsite = firebase.database().ref('sites').orderByChild('used').equalTo(true)
    state.refsite.on('child_changed', snapshot => {
      for (const x in state.sites) {
        if (state.sites[x].key === snapshot.val().key) {
          state.sites[x].status = snapshot.val().status
        }
      }
      commit('SET_SITES', state.sites)
    })
  },
  async refreshCreditAll ({ commit, state },check) {
    let key = state.user.key
    if (!key) {
      const user = localStorage.getItem('userData')
      if (user) {
        const _user = JSON.parse(user)
        key = _user.key
      }
    }
    const { data } = await axios.get('https://ap11.fast356.biz/api/getbalance/' + key)
    if (data.status) {
      if (check && check.checkamb && data.credit && data.amb && data.amb.credit && data.credit === data.amb.credit) {
        data.amb.credit = 0
      }
      commit('SET_SITES_CREDIT', data)
    }
  },
  async moveCreditToWallet ({ dispatch, state }) {
    let key = state.user.key
    if (!key) {
      const user = localStorage.getItem('userData')
      if (user) {
        const _user = JSON.parse(user)
        key = _user.key
      }
    }
    const { data } = await axios.get('https://ap11.fast356.biz/api/movetowallet/' + key)
    if (data.status) {
      dispatch('refreshCreditAll', { checkamb: true })
    }
  },
  async getLastBonus ({ commit }, user) {
    const response = { status: false, bonus: 0, canwithdraw: 0, turncredit: 0 }
    await firebase.firestore().collection('krungsri').where('userkey', '==', user.key).where('status', '==', 'ดำเนินการแล้ว').orderBy('date', 'desc').limit(1).get().then((snapshot) => {
      snapshot.forEach((doc) => {
        response.bonus = (doc.data().bonus) ? doc.data().bonus : 0
        response.canwithdraw = (doc.data().canwithdraw) ? doc.data().canwithdraw : 0
        response.turncredit = (doc.data().turncredit) ? doc.data().turncredit : 0
      })
    })
    response.status = true
    commit('SET_LAST_BONUS', response)
  },
  async checkPromotions ({ commit, state }) {
    let promotions = {}
    let pro = false
    if (state.user.disablepro && state.user.disablepro.promotion === false) {
      commit('SET_PROMOTIONS', promotions)
      return
    }
    // สมัครสมาชิกใหม่
    await firebase.firestore().collection('krungsri').where('userkey', '==', state.user.key).orderBy('createdate', 'asc').limit(2).get().then(async (snapshot) => {
      let check = false
      if (!snapshot.size) {
        check = true
      } else if (snapshot.size === 1) {
        snapshot.forEach((doc) => {
          if (doc.data().bonus && (!doc.data().qty || doc.data().qty === 0)) {
            check = true
          }
        })
      }
      if (check) {
        await firebase.firestore().collection('promotions').where('type', '==', 'สมัครสมาชิกใหม่').where('status', '==', true).limit(1).get().then((snapshot2) => {
          snapshot2.forEach((doc) => {
            promotions = doc.data()
            pro = true
          })
        })
      }
    })
    // โปรพิเศษ
    if (!pro) {
      const time = moment().tz('Asia/Bangkok').format('HHmm')
      await firebase.firestore().collection('promotions').where('type', '==', 'โปรพิเศษ').where('status', '==', true).get().then(async (snapshot2) => {
        let promotionId = ''
        let checkMaxReceive = 0
        snapshot2.forEach((doc) => {
          const starttime = doc.data().starttime.replace(':', '')
          const endtime = doc.data().endtime.replace(':', '')
          if (Number(time) >= Number(starttime) && Number(time) <= Number(endtime)) {
            pro = true
            promotions = doc.data()
            promotionId = doc.id
            checkMaxReceive = doc.data().maxreceive
          }
        })
        if (checkMaxReceive) {
          await firebase.firestore().collection('promotion_users').where('userkey', '==', state.user.key).where('promotionId', '==', promotionId).where('date', '==', moment().tz('Asia/Bangkok').format('YYYY-MM-DD')).get().then(async (snapshot) => {
            if (snapshot.size >= checkMaxReceive) {
              pro = false
              promotions = {}
            }
          })
        }
      })
    }
    // ฝากแรกของวัน
    if (!pro) {
      await firebase.firestore().collection('krungsri').where('userkey', '==', state.user.key).where('createdate', '>=', moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + ' 00:00:00').limit(2).get().then(async (snapshot) => {
        let check = false
        if (!snapshot.size) {
          check = true
        } else if (snapshot.size === 1) {
          snapshot.forEach((doc) => {
            if (doc.data().bonus && (!doc.data().qty || doc.data().qty === 0)) {
              check = true
            }
          })
        }
        if (check) {
          await firebase.firestore().collection('promotions').where('type', '==', 'ฝากแรกของวัน').where('status', '==', true).get().then((snapshot2) => {
            snapshot2.forEach((doc) => {
              promotions = doc.data()
              pro = true
            })
          })
        }
      })
    }
    // โปรช่วงเวลา
    if (!pro) {
      const time = moment().tz('Asia/Bangkok').format('HHmm')
      await firebase.firestore().collection('promotions').where('type', '==', 'โปรช่วงเวลา').where('status', '==', true).get().then(async (snapshot2) => {
        snapshot2.forEach((doc) => {
          const starttime = doc.data().starttime.replace(':', '')
          const endtime = doc.data().endtime.replace(':', '')
          if (Number(time) >= Number(starttime) && Number(time) <= Number(endtime)) {
            pro = true
            promotions = doc.data()
          }
        })
      })
    }
    // โปรฝากทั้งวัน
    if (!pro) {
      await firebase.firestore().collection('promotions').where('type', '==', 'ฝากทั้งวัน').where('status', '==', true).get().then(async (snapshot2) => {
        snapshot2.forEach((doc) => {
          pro = true
          promotions = doc.data()
        })
      })
    }
    let gotpro = false
    if (pro) {
      const _user = (await firebase.database().ref(`/users/${state.user.key}`).once('value')).val()
      gotpro = (_user.bonus === 'Y')
    }
    commit('SET_PROMOTIONS', promotions)
    commit('SET_GOT_PRO', gotpro)
  },
  async getRewards ({ commit }) {
    const rewards = (await firebase.database().ref('/rewards').orderByChild('status').equalTo('Y').once('value')).val()
    for (const x in rewards) {
      rewards[x].key = x
    }
    commit('SET_REWARDS', _.orderBy(rewards, ['point'], ['asc']))
  },
}
